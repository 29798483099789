<template>
  <header class="header">
    <a class="logo" title="几人作" @click="$router.push('/home')">
      <img src="@/assets/images/logo.png" alt="" />
      <el-link
        :underline="false"
        style="font-size: 18px; font-weight: 600; color: #fff"
        >幾人作</el-link
      >
    </a>
    <div class="loginList">
      <el-dropdown @visible-change="handleMessage" trigger="click">
        <el-badge :value="userInfo.tipNum || 0" class="loginListItem">
          <img src="./images/hader1.png" alt="" />
        </el-badge>
        <el-dropdown-menu style="width: 420px; padding: 6px" slot="dropdown">
          <div class="dropdownBox">
            <div class="title">最新消息</div>
            <div class="contents">
              <div
                class="item"
                v-for="item in messageList"
                :key="item.messageId"
              >
                <div class="title">
                  {{ item.brief }}
                </div>
                <div class="time">
                  {{ item.createTime }}
                </div>
                <div class="rightLink">详情>></div>
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown @visible-change="handleMessage" trigger="click">
        <el-badge :value="userInfo.noticeNum || 0" class="loginListItem">
          <img src="./images/hader2.png" alt="" />
        </el-badge>
        <el-dropdown-menu style="width: 420px; padding: 6px" slot="dropdown">
          <div class="dropdownBox">
            <div class="title">最新消息</div>
            <div class="contents">
              <div
                class="item"
                v-for="item in messageList"
                :key="item.messageId"
              >
                <div class="title">
                  {{ item.brief }}
                </div>
                <div class="time">
                  {{ item.createTime }}
                </div>
                <div class="rightLink">详情>></div>
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="shu"></div>
      <!-- 用户登录了，显示用户信息与退出登录 ||用户没有登录，显示的是登录与注册-->
      <div v-if="accountId" class="buttons">
        <div class="buttonItem">
          <el-dropdown @visible-change="handleMessage" trigger="click">
            <span class="el-dropdown-link">
              <img src="./images/msg.png" alt="" />
            </span>
            <el-dropdown-menu
              style="width: 420px; padding: 6px"
              slot="dropdown"
            >
              <div class="dropdownBox">
                <div class="title">最新消息</div>
                <div class="contents">
                  <div
                    class="item"
                    v-for="item in messageList"
                    :key="item.messageId"
                  >
                    <div class="title">
                      {{ item.brief }}
                    </div>
                    <div class="time">
                      {{ item.createTime }}
                    </div>
                    <div class="rightLink">详情>></div>
                  </div>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="buttonItem">
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="el-dropdown-link">
              <img src="./images/black.png" alt="" />
            </span>
            <el-dropdown-menu
              style="width: 180px; padding: 6px"
              slot="dropdown"
            >
              <div class="dropdownUrl">
                <span>0x86e793e413f519b450315fcc4b618eb25a3a54a4</span>
                <img
                  class="imgButton"
                  src="@/assets/images/copy.png"
                  @click="copy"
                />
              </div>
              <el-dropdown-item command="1">
                <div class="dropdownItem">
                  <span>我的作品</span>
                  <div class="button">
                    <i class="el-icon-document" />
                    <span>{{  userInfo.myArticleNum }}</span>
                    <el-divider direction="vertical"></el-divider>
                    <i class="el-icon-edit-outline" />
                    <span>{{  userInfo.myRenewNum }}</span>
                  </div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="2">我的数字资产</el-dropdown-item>
              <el-dropdown-item command="3">我的收藏</el-dropdown-item>
              <el-dropdown-item command="4">账号信息</el-dropdown-item>
              <el-dropdown-item command="5">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="buttonItem walle">
          <img src="./images/btc.png" style="margin: 6px" alt="" />
          <span>{{ userInfo.walletBalance }}</span>
        </div>
        <div class="buttonItem nickName">
          <!-- <img :src="./images/userImg.png" style="margin-right: 8px" alt="" /> -->
          <img :src="userInfo.userHead||`./images/userImg.png`" style="margin-right: 8px;width:32px;height:32px;" alt="" />
          <span> {{ userInfo.nickName }}</span>
        </div>
        <el-button size="small" type="primary" @click="goToCreate"
          ><span style="color: #000; font-weight: 600">开篇创作+</span>
        </el-button>
      </div>
      <div v-else class="buttons">
        <el-button size="small" type="primary" @click="goTologin"
          ><span style="color: #000; font-weight: 600">账号登录/注册</span>
        </el-button>
      </div>
    </div>
  </header>
</template>
 
<script>
import { modifyInfo, message } from "@/api";
import { mapState } from "vuex";
export default {
  name: "haders",
  data() {
    return {
      messageList: [],
    };
  },
  methods: {
    copy() {
      this.$copyText("0x86e793e413f519b450315fcc4b618eb25a3a54a4").then(
        () => {
          this.$message.success("已成功复制到粘贴板");
        },
        () => {
          this.$message("复制失败，请手动复制！");
        }
      );
    },
    // 下拉菜单的点击事件
    handleCommand(commarnd) {
      if (commarnd == "1") {
        this.$router.push("/myWork");
      } else if (commarnd == "2") {
        this.$router.push("/myMoney");
      }
      //退出登录
      else if (commarnd == "5") {
        this.$store.dispatch("userLogout", this.accountId);
      }
    },
    // 消息弹框点击事件
    handleMessage(show) {
      if (show) {
        let param = {
          noticeType: 3, //1 公告信息 2 平台信息 3 个人消息
          accountId: this.accountId,
          pageNo: 1,
          pageSize: 20,
        };
        message(param).then(({ code, msg, data }) => {
          if (code == 1000) {
            this.messageList = data;
          }
        });
      }
    },
    goTologin() {
      this.$router.push("/login");
    },
    goToCreate() {
      if (this.userInfo.nickName) {
        localStorage.removeItem("agoId");
        this.$router.push({
          path: "/Create",
          query: {
            type: 1, // 存创作类型
          },
        });
      } else {
        this.$alert("请登录您的几人作账号", "提示", {
          confirmButtonText: "前往登录",
          callback: (action) => {
            this.$router.push("/login");
          },
        });
        // this.$prompt("请输入昵称", "你需要先设置一个昵称:", {
        //   confirmButtonText: "提交",
        //   inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
        //   inputErrorMessage: "昵称格式不符合要求，请修改后提交",
        // }).then(({ value }) => {
        //   modifyInfo({ nickName: value }).then(({ data, success }) => {
        //     if (success) {
        //       // this.$store.dispatch("getUserInfo");
        //     }
        //   });
        // });
      }
    },
  },

  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 10px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    display: flex;
    align-items: center;
    img {
      width: 36px;
      margin-right: 10px;
    }
  }

  .loginList {
    padding: 0 10px;
    display: flex;
    align-items: center;

    .loginListItem {
      margin: 0 10px;
      img {
        width: 26px;
      }
    }
    .buttons {
      margin-left: 20px;
      display: flex;
      align-items: center;
      .buttonItem {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 50px;
        color: #828282;
        img {
          width: 34px;
        }
      }

      .walle {
        width: 100px;
        justify-content: left;
      }
      .nickName {
        width: 150px;
      }
    }
    .shu {
      width: 3px;
      height: 30px;
      margin-left: 16px;
      background-color: #16191e;
    }
  }
}
.dropdownUrl {
  position: relative;
  width: 180px;
  height: 52px;
  display: black;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 18px;
  background-color: #e9e9ea;
  color: #06070c;
  box-sizing: border-box;
  word-wrap: break-word;
  .imgButton {
    width: 18px;
    position: absolute;
    right: 6px;
    bottom: 6px;
    cursor: pointer;
  }
}
.dropdownItem {
  display: flex;
  align-items: center;
  .button {
    margin-left: 30px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}

/deep/.el-dropdown-menu__item {
  padding: 0 0 0 6px;
  color: #06070c;
  font-size: 12px;
  background: none !important;
}
/deep/.el-badge__content {
  background-color: #d81e06;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  padding: 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.dropdownBox {
  color: #000;
  .title {
    font-size: 16px;
    line-height: 40px;
  }
  .contents {
    .item {
      height: 58px;
      background: #eee;
      margin-bottom: 12px;
      position: relative;
      .title {
        color: #06070c;
        font-size: 14px;
      }
      .time {
        color: #999;
      }
      .rightLink {
        float: right;
        color: #01e39b;
        position: absolute;
        right: 6px;
        top: 22px;
        cursor: pointer;
      }
    }
  }
}
</style>
