// sm2Util.js
import moment from 'moment'
const SM2 = require('sm-crypto').sm2;
import { getDeviceToken, setDeviceToken } from '@/utils/auth'
import { randomString } from '@/utils'


/* 
 * 加签工具函数
 * @param {String} text 待加签文本
 */
export function encryptSm2(text,key) {
    return SM2.doSignature(text, key, { hash: true, der: true });
}

export function returnHeader(key) {
    let newConfig = {}
    let deviceToken = getDeviceToken()
    if(!deviceToken){
        deviceToken = `xhapi${randomString(20)}`
        setDeviceToken(deviceToken)
    }
    newConfig.deviceToken = deviceToken
    newConfig.version = '1.0.0'
    newConfig.time = moment().format('yyyy-MM-DD HH:mm:ss');
    newConfig.channel = '1'
    newConfig.osType = '4'
    newConfig.appType = '4'
    let sginStr = `deviceToken=${newConfig.deviceToken}&time=${newConfig.time}&version=${newConfig.version}`
    sginStr = `${sginStr}&channel=${newConfig.channel}&osType=${newConfig.osType}`
    let sign = encryptSm2(sginStr,key);
    newConfig.sign = sign
    return newConfig
}
