//统一管理项目全部接口的文件
//获取真实服务器的接口的数据利用当前axios
import request from './request';
//如果获取mock接口的数据，利用底下axios

//获取钱包余额
export const createQueryBalance = (data) => request({
    url: '/wallet/createQueryBalance',
    method: 'post',
    data
});
//ai文本记录
export const chatRecord = (data) => request({
    url: '/chat/list',
    method: 'post',
    data
});
//ai生成文本
export const chat = (data) => request({
    url: '/chat/chatQuestion',
    method: 'post',
    data
});
//ai生成绘画
export const imageGenerate = (data) => request({
    url: '/chat/imageGenerate',
    method: 'post',
    data
});

//插图片
export const searchImageResult = (data) => request({
    url: '/chat/searchImageByJobId',
    method: 'post',
    data
});
//历史图片
export const searchHistoryImg = (data) => request({
    url: '/chat/imageGenerateList',
    method: 'post',
    data
});


//生成文章
export const save = (data) => request({
    url: '/chat/save',
    method: 'post',
    data
});

//我的作品
export const personalPage = (data) => request({
    url: '/account/articleList',
    method: 'post',
    data
});
 
//创作、续篇接口
export const createArticle = (data) => request({
    url: '/chat/createArticle',
    method: 'post',
    data
});

//获取兑换的nft信息
export const exchangeInfo = (data) => request({
    url: '/nft/exchange-info',
    method: 'post',
    data
});
//个人的nft信息
export const myNft = (data) => request({
    url: '/nft/personal-page',
    method: 'post',
    data
});
//获取nft绑定信息
export const nftArticleIdNum = (data) => request({
    url: '/nft/nft-articleId-num',
    method: 'post',
    data
});
//兑换支付nft
export const payNft = (data) => request({
    url: '/pay/nft',
    method: 'post',
    data
});

