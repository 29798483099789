import Cookies from "js-cookie";

const PrivateKeyKey = "Admin-PrivateKey-xinhuaweb";
const UserId = "Admin-user-code";
const DeviceTokenKey = "DeviceToken-xinhuaweb";
const LoginInfoKey = "xinhuaweb-loginInfo";

export function getPrivateKey() {
  return Cookies.get(PrivateKeyKey);
} 

export function setPrivateKeyVal(privateKey) {
  return Cookies.set(PrivateKeyKey, privateKey);
}

export function removePrivateKey() {
  return Cookies.remove(PrivateKeyKey);
}
export function getUserId() {
  return localStorage.getItem(UserId)
}

export function setUserId(userId) {
  return localStorage.setItem(UserId, userId);
}

export function removeUserId() {
  localStorage.removeItem(UserId);
  localStorage.removeItem(LoginInfoKey);
}

export function getDeviceToken() {
  return Cookies.get(DeviceTokenKey);
}

export function setDeviceToken(DeviceToken) {
  return Cookies.set(DeviceTokenKey, DeviceToken);
}

export function removeDeviceToken() {
  return Cookies.remove(DeviceTokenKey);
}
export function getLoginInfo() {
  let returnInfo = localStorage.getItem(LoginInfoKey);
  if (returnInfo) {
    returnInfo = JSON.parse(returnInfo);
  }
  return returnInfo;
}

export function setLoginInfo(LoginInfo) {
  let setInfo = LoginInfo;
  if (setInfo) {
    if (setInfo.mobile) {
      localStorage.setItem("mobile", setInfo.mobile);
    }
    setInfo = JSON.stringify(setInfo);
  }
  return localStorage.setItem(LoginInfoKey, setInfo);
}

export function removeLoginInfo() {
  return Cookies.remove(LoginInfoKey);
}