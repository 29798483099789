//axios进行二次封装
import axios from "axios";
//进度条
import nprogress from "nprogress";
//因为进度条样式咱们没有引入
import "nprogress/nprogress.css";
//引入Vuex仓库模块
import { Notification, MessageBox, Message, Loading } from "element-ui";
import store from "@/store";
import { getPrivateKey } from "@/utils/auth";
import { returnHeader } from "@/utils/sm2Util";
import { tansParams, blobValidate } from "@/utils/ruoyi";
import cache from "@/plugins/cache";
import { setSm4 } from "@/utils/sm4Util";

axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_URL,
  // 超时
  timeout: 120000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 privateKey
    // const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    let privateKey = getPrivateKey();
    if(config.headers.isPrivateKey||!privateKey){
      privateKey =
      "d1f7606da81eabbfb8b1e27622284e75ad4ef99dfd743f09196d8f2dedd22ba8"; // 私钥
    }
    
    let newConfig = returnHeader(privateKey);
    config.headers = newConfig;

    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (
      !isRepeatSubmit &&
      (config.method === "post" || config.method === "put")
    ) {
      const requestObj = {
        url: config.url,
        data:
          typeof config.data === "object"
            ? JSON.stringify(config.data)
            : config.data,
        time: new Date().getTime(),
      };
      const sessionObj = cache.session.getJSON("sessionObj");
      if (
        sessionObj === undefined ||
        sessionObj === null ||
        sessionObj === ""
      ) {
        cache.session.setJSON("sessionObj", requestObj);
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 500; // 间隔时间(ms)，小于此时间视为重复提交
        if (
          s_data === requestObj.data &&
          requestObj.time - s_time < interval &&
          s_url === requestObj.url
        ) {
          const message = "数据正在处理，请勿重复提交";
          console.warn(`[${s_url}]: ` + message);
          return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON("sessionObj", requestObj);
        }
      }
    }
    //     //检测到程序发请求，请求拦截器可以检测到，进度条开始动
    // nprogress.start();
    let formData = new FormData();
    for (let key in config.data) {
      if (config.data.hasOwnProperty(key)) {
        let ele = config.data[key];
        formData.append(key, ele);
      }
    }
    config.data = formData;
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    console.log(res)
    // 未设置状态码则默认成功状态
    const code = res.code || res.data.code || 1000;
    const msg = res.msg || res.data.msg || '';
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code == 2001||code == 1002) {
      let numbers = localStorage.getItem("mobile");
      // 如果报错未登录，且用户手机号存在就重新登录
      if (numbers) {
        store.dispatch("userLogin", {
          mobile: setSm4(numbers),
          verifyCode: "168168",
        });
        setTimeout(() => {
          window.location.reload() 
        }, 200);
      }
    }
    if (code !== 1000) {
      // Notification.error({
      //   title: msg,
      // });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  // (error) => {
  //   console.log("err" + error);
  //   let { message } = error;
  //   if (message == "Network Error") {
  //     message = "后端接口连接异常";
  //   } else if (message.includes("timeout")) {
  //     message = "系统接口请求超时";
  //   } else if (message.includes("Request failed with status code")) {
  //     message = "系统接口" + message.substr(message.length - 3) + "异常";
  //   }
  //   Message({
  //     message: message,
  //     type: "error",
  //     duration: 5 * 1000,
  //   });
  //   return Promise.reject(error);
  // }
);

export default service;
