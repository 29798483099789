<template>
  <div class="app" id="app">
    <Header v-show="$route.meta.show"></Header>
    <!-- 路由组件出口的地方:展示的地方 -->
    <router-view></router-view>
    <!-- 显示:首页、搜索显示  隐藏：登录、注册 -->
    <!--v-show="$route.path=='/home'||$route.path=='/search'"-->
    <Footer v-show="$route.meta.footshow"></Footer>
  </div>
</template>

<script>
//引入非路由组件
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "",
  components: {
    Header,
    Footer,
  },
  //根组件执行执行一次，因此mounted执行一次
  mounted() {
    //在根组件渲染出来的时候，向服务器派发action，通过Vuex向服务器发请求，获取三级联动的数据
    //将来给vuex提供数据
    //写在这里的好处:1,请求只会发一次   2：在使用数据之前（数据在Vuex中已经存在，且只是向服务器发起一次请求）
    // this.$store.dispatch('getCategory');
  },
};
</script>
<style>
body {
  width: 100% !important;
}

.app {
  background-color: #06070C;
  color: #E6E6E6;
}
.el-dialog__title{
  color: #333 !important;
  font-size: 14px!important;
}
</style>
