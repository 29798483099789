import Vue from "vue";
import App from "./App.vue";
//引入路由
import router from "@/router";
import store from "@/store";

//这里是入口文件，也是程序最先执行的地方
//注册全局组件---三级联动
// import Pagination from '@/components/Pagination';
//全局组件注册方式:
//第一参数:组件名字  第二个参数：哪一个组件
//elementUI注册组件其中有一种写法，就是这种写法
// Vue.component(Pagination.name,Pagination);
//引入先关的mock数据的文件【需要代码执行一次】
import "@/mock/serve";
//引入swiper需要使用的样式[项目当中用到swiper的地方很多，样式引入一次即可
import "swiper/css/swiper.css";
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);
/******************************************************************* */
//elementUI
//这种引入方式----引入全部的组件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./theme/index.css";
Vue.use(ElementUI);
/*********************************************************************/
//图片懒加载
// import VueLazyload from 'vue-lazyload'
//使用图片懒加载插件：自定义插件【Vue.use】

//引入自定义插件
import jch from "@/plugins/jch";
//Vue.use使用这个插件的时候，会出发自定义插件对象的install方法
Vue.use(jch);

import "@/plugins/valadiate";
//创建Vue类的一个实例
new Vue({
  render: (h) => h(App),
  beforeCreate() {
    //配置全局事件总线
    Vue.prototype.$bus = this;
    //把全部的请求函数：作为Vue.prototype的属性，组件实例可以获取
  },
  //注册路由,给组件的身上添加了$router与$route两个属性
  router,
  //注册仓库，给每一个组件的身上添加$store属性
  store,
}).$mount("#app");
