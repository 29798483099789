export default [
  {
    path: "/home",
    //component右侧数值：放置的是一个箭头函数，当这个home路由被访问的时候，才会执行；
    //当用户访问当前Home的时候，我才加载Home路由组件，不访问，不加载当前Home路由组件
    component: () => import("@/pages/Home"),
    //路由元信息---控制footer显示与隐藏
    meta: { show: true,footshow:true },
  },
  {
    path: "/login",
    component: () => import("@/pages/Login"),
    meta: { show: false,footshow:false },
  },
  {
    path: "/Details",
    component: () => import("@/pages/Details"),
    meta: { show: true,footshow:false },
  },
  // 重定向
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/Create",
    component: () => import("@/pages/Create"),
    meta: { show: true,footshow:false },
  },
  {
    path: "/step",
    component: () => import("@/pages/Create/step.vue"),
    meta: { show: true,footshow:false },
  },
  {
      path: '/myWork',
      component: () => import('@/pages/myWork'),
      meta: { show: true ,footshow:true}
  },
  {
      path: '/myMoney',
      component: () => import('@/pages/myMoney'),
      meta: { show: true,footshow:true }
  },
  {
      path: '/income',
      component: () => import('@/pages/myMoney/income'),
      meta: { show: true ,footshow:true}
  },
  {
      path: '/success',
      component: () => import('@/pages/AICreate/success.vue'),
      meta: { show: true,footshow:false }
  },
  // 设置
  {
    path: "/AICreate",
    component: () => import("@/pages/AICreate"),
    meta: { show: true ,footshow:false},
    children: [
      {
        path: "/AIText",
        component: () => import("@/pages/AICreate/AIText.vue"),
        meta: { show: true,footshow:false },
      },
      {
        path: "/AIImg",
        component: () => import("@/pages/AICreate/AIImg.vue"),
        meta: { show: true,footshow:false },
      },
      {
        path: "/AICreate",
        redirect: "/AIText",
      },
    ],
  },
];
