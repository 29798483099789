import { exchangeInfo } from "@/api/nft";
const state = {
  nft: JSON.parse(localStorage.getItem("NFT")) || {
    contractAddress: "",
    exchangeEth: 0,
    fileUrl: "",
    goodName: "",
    goodNo: "",
    nftId: "",
    serialNumber: "",
  },
  
  article: JSON.parse(localStorage.getItem("article")) || {
    title: "",
    description: "",
  },
  agoId:Number( localStorage.getItem("agoId") )|| 0, // 原作id
  agoIdXX:Number( localStorage.getItem("agoIdXX") )|| 0, // 被续写的原作id
};
const mutations = {
  // 原作id
  SETAGOID(state, id) {
    state.agoId = id;
    localStorage.setItem("agoId", id);
  },
  //  被续写的原作id
  SETAGOIDXX(state, id) {
    state.agoIdXX = id;
    localStorage.setItem("agoIdXX", id);
  },
  SETNFT(state, data) {
    state.nft = data;
    //持久化存储
    localStorage.setItem("NFT", JSON.stringify(data));
  },
  // 正在创作的内容，问题和title
  ARTICLE(state, data) {
    state.article = data;
    localStorage.setItem("article", JSON.stringify(data));
  },
};
const actions = {
  async getPayNum({ commit }, type) {
    let res = await exchangeInfo({ type });
    if (res.success) {
      commit("SETNFT", res.data);
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.respMsg);
    }
  },
};
const getters = {};
export default {
  state,
  mutations,
  actions,
  getters,
};
