//统一管理项目全部接口的文件
//获取真实服务器的接口的数据利用当前axios
import request from './request';
//如果获取mock接口的数据，利用底下axios

//登录接口
///api/user/passport/login  post phone password
export const reqLogin = (data) => request({
    url: '/user/loginByMobileSms',
    method: 'post',
    headers:{
        isPrivateKey:true
    },
    data
});

//短信验证码
export const sendAccountVerifyCode = (data) => request({
    url: '/user/sendAccountVerifyCode',
    method: 'post',
    headers:{
        isPrivateKey:true
    },
    data
});


//首页接口
export const getHome = (data) => request({
    url: `/home/index`,
    method: 'post',
    headers:{
        isPrivateKey:true
    },
    data
});
//修改用户信息的接口
export const modifyInfo = (data) => request({
    url: `/account/updateAccountInfo`,
    method: 'post',
    data
});


//退出登录的接口【通知服务器销毁当前token身份】
export const reqLogout = () => request({
    url: `/user/loginOut`,
    method: 'post'
});


//消息列表（公告、平台消息调用）
export const message = (data) => request({
    url: `/message/list`,
    method: 'post',
    data
});
