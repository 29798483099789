//首页列表
export function setHomeList(list) {
   return localStorage.setItem('homeList', JSON.stringify(list))
}
export const clearHomeList =()=>{
   localStorage.removeItem('homeList');
}

export const getHomeList = ()=>{
   let list = localStorage.getItem('homeList')
   if(list){
      list = JSON.parse(list)
   }
   return list
}